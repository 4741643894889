.m_b37d9ac7 {
  width: calc(100% - var(--mantine-spacing-md) * 2);
  position: fixed;
  z-index: var(--notifications-z-index);
  max-width: var(--notifications-container-width);
}

  .m_b37d9ac7:where([data-position='top-center']) {
    top: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='top-left']) {
    top: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='top-right']) {
    top: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-center']) {
    bottom: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='bottom-left']) {
    bottom: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-right']) {
    bottom: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

.m_5ed0edd0 + .m_5ed0edd0 {
    margin-top: var(--mantine-spacing-md);
  }

body {
  background-color: var(--colors-light);
  color: var(--colors-dark);
}
  [data-mantine-color-scheme='dark'] body{
    background-color: var(--colors-dark)
}
  [data-mantine-color-scheme='dark'] body{
    color: var(--colors-light)
}@layer mantine;@layer reset, base, tokens, recipes, utilities;@layer base {
  :root {
    --made-with-panda: '🐼'
    }

  *, *::before, *::after, ::backdrop {
    --blur:   ;
    --brightness:   ;
    --contrast:   ;
    --grayscale:   ;
    --hue-rotate:   ;
    --invert:   ;
    --saturate:   ;
    --sepia:   ;
    --drop-shadow:   ;
    --backdrop-blur:   ;
    --backdrop-brightness:   ;
    --backdrop-contrast:   ;
    --backdrop-grayscale:   ;
    --backdrop-hue-rotate:   ;
    --backdrop-invert:   ;
    --backdrop-opacity:   ;
    --backdrop-saturate:   ;
    --backdrop-sepia:   ;
    --scroll-snap-strictness: proximity;
    --border-spacing-x: 0;
    --border-spacing-y: 0;
    --translate-x: 0;
    --translate-y: 0;
    --rotate: 0;
    --skew-x: 0;
    --skew-y: 0;
    --scale-x: 1;
    --scale-y: 1
    }
}@layer reset {
  * {
    margin: 0;
    padding: 0;
    font: inherit;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: var(--global-color-border, currentColor);
  }

  html {
    line-height: 1.5;
    --font-fallback: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-tab-size: 4;
    -o-tab-size: 4;
       tab-size: 4;
    font-family: var(--global-font-body, var(--font-fallback));
  }

  hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }

  body {
    height: 100%;
    line-height: inherit;
  }

  img {
    border-style: none;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  ol,
  ul {
    list-style: none;
  }

  code,
  kbd,
  pre,
  samp {
    font-size: 1em;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
  }

  button,
  select {
    text-transform: none;
  }

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }

  input::-moz-placeholder, textarea::-moz-placeholder {
    opacity: 1;
    color: var(--global-color-placeholder, #9ca3af);
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 1;
    color: var(--global-color-placeholder, #9ca3af);
  }

  textarea {
    resize: vertical;
  }

  summary {
    display: list-item;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  dialog {
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp,
  pre {
    font-size: 1em;
    --font-mono-fallback: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New';
    font-family: var(--global-font-mono, var(--font-fallback));
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  :-moz-focusring {
    outline: auto;
  }
}@layer tokens {
  :where(:root, :host) {
    --borders-none: none;
    --easings-default: cubic-bezier(0.4, 0, 0.2, 1);
    --easings-linear: linear;
    --easings-in: cubic-bezier(0.4, 0, 1, 1);
    --easings-out: cubic-bezier(0, 0, 0.2, 1);
    --easings-in-out: cubic-bezier(0.4, 0, 0.2, 1);
    --durations-fastest: 50ms;
    --durations-faster: 100ms;
    --durations-fast: 150ms;
    --durations-normal: 200ms;
    --durations-slow: 300ms;
    --durations-slower: 400ms;
    --durations-slowest: 500ms;
    --radii-xs: 0.125rem;
    --radii-sm: 0.25rem;
    --radii-md: 0.375rem;
    --radii-lg: 0.5rem;
    --radii-xl: 0.75rem;
    --radii-2xl: 1rem;
    --radii-3xl: 1.5rem;
    --radii-full: 9999px;
    --font-weights-thin: 100;
    --font-weights-extralight: 200;
    --font-weights-light: 300;
    --font-weights-normal: 400;
    --font-weights-medium: 500;
    --font-weights-semibold: 600;
    --font-weights-bold: 700;
    --font-weights-extrabold: 800;
    --font-weights-black: 900;
    --line-heights-none: 1;
    --line-heights-tight: 1.25;
    --line-heights-snug: 1.375;
    --line-heights-normal: 1.5;
    --line-heights-relaxed: 1.625;
    --line-heights-loose: 2;
    --fonts-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --fonts-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    --fonts-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --letter-spacings-tighter: -0.05em;
    --letter-spacings-tight: -0.025em;
    --letter-spacings-normal: 0em;
    --letter-spacings-wide: 0.025em;
    --letter-spacings-wider: 0.05em;
    --letter-spacings-widest: 0.1em;
    --shadows-xs: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --shadows-sm: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --shadows-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --shadows-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --shadows-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --shadows-2xl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --shadows-inner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    --blurs-sm: 4px;
    --blurs-base: 8px;
    --blurs-md: 12px;
    --blurs-lg: 16px;
    --blurs-xl: 24px;
    --blurs-2xl: 40px;
    --blurs-3xl: 64px;
    --spacing-0: 0rem;
    --spacing-1: 0.25rem;
    --spacing-2: 0.5rem;
    --spacing-3: 0.75rem;
    --spacing-4: 1rem;
    --spacing-5: 1.25rem;
    --spacing-6: 1.5rem;
    --spacing-7: 1.75rem;
    --spacing-8: 2rem;
    --spacing-9: 2.25rem;
    --spacing-10: 2.5rem;
    --spacing-11: 2.75rem;
    --spacing-12: 3rem;
    --spacing-14: 3.5rem;
    --spacing-16: 4rem;
    --spacing-20: 5rem;
    --spacing-24: 6rem;
    --spacing-28: 7rem;
    --spacing-32: 8rem;
    --spacing-36: 9rem;
    --spacing-40: 10rem;
    --spacing-44: 11rem;
    --spacing-48: 12rem;
    --spacing-52: 13rem;
    --spacing-56: 14rem;
    --spacing-60: 15rem;
    --spacing-64: 16rem;
    --spacing-72: 18rem;
    --spacing-80: 20rem;
    --spacing-96: 24rem;
    --spacing-0\.5: 0.125rem;
    --spacing-1\.5: 0.375rem;
    --spacing-2\.5: 0.625rem;
    --spacing-3\.5: 0.875rem;
    --sizes-0: 0rem;
    --sizes-1: 0.25rem;
    --sizes-2: 0.5rem;
    --sizes-3: 0.75rem;
    --sizes-4: 1rem;
    --sizes-5: 1.25rem;
    --sizes-6: 1.5rem;
    --sizes-7: 1.75rem;
    --sizes-8: 2rem;
    --sizes-9: 2.25rem;
    --sizes-10: 2.5rem;
    --sizes-11: 2.75rem;
    --sizes-12: 3rem;
    --sizes-14: 3.5rem;
    --sizes-16: 4rem;
    --sizes-20: 5rem;
    --sizes-24: 6rem;
    --sizes-28: 7rem;
    --sizes-32: 8rem;
    --sizes-36: 9rem;
    --sizes-40: 10rem;
    --sizes-44: 11rem;
    --sizes-48: 12rem;
    --sizes-52: 13rem;
    --sizes-56: 14rem;
    --sizes-60: 15rem;
    --sizes-64: 16rem;
    --sizes-72: 18rem;
    --sizes-80: 20rem;
    --sizes-96: 24rem;
    --sizes-0\.5: 0.125rem;
    --sizes-1\.5: 0.375rem;
    --sizes-2\.5: 0.625rem;
    --sizes-3\.5: 0.875rem;
    --sizes-xs: 20rem;
    --sizes-sm: 24rem;
    --sizes-md: 28rem;
    --sizes-lg: 32rem;
    --sizes-xl: 36rem;
    --sizes-2xl: 42rem;
    --sizes-3xl: 48rem;
    --sizes-4xl: 56rem;
    --sizes-5xl: 64rem;
    --sizes-6xl: 72rem;
    --sizes-7xl: 80rem;
    --sizes-8xl: 90rem;
    --sizes-prose: 65ch;
    --sizes-full: 100%;
    --sizes-min: min-content;
    --sizes-max: max-content;
    --sizes-fit: fit-content;
    --sizes-breakpoint-sm: 640px;
    --sizes-breakpoint-md: 768px;
    --sizes-breakpoint-lg: 1024px;
    --sizes-breakpoint-xl: 1280px;
    --sizes-breakpoint-2xl: 1536px;
    --animations-spin: spin 1s linear infinite;
    --animations-ping: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
    --animations-pulse: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    --animations-bounce: bounce 1s infinite;
    --font-sizes-2xs: 0.5rem;
    --font-sizes-md: 1rem;
    --font-sizes-7xl: 4.5rem;
    --font-sizes-8xl: 6rem;
    --font-sizes-9xl: 8rem;
    --font-sizes-xs: .75rem;
    --font-sizes-sm: .875rem;
    --font-sizes-base: 1rem;
    --font-sizes-lg: 1.125rem;
    --font-sizes-xl: 1.25rem;
    --font-sizes-2xl: 1.5rem;
    --font-sizes-3xl: 1.875rem;
    --font-sizes-4xl: 2.25rem;
    --font-sizes-5xl: 3rem;
    --font-sizes-6xl: 4rem;
    --colors-transparent: rgb(0 0 0 / 0);
    --colors-rose-50: #fff1f2;
    --colors-rose-100: #ffe4e6;
    --colors-rose-200: #fecdd3;
    --colors-rose-300: #fda4af;
    --colors-rose-400: #fb7185;
    --colors-rose-500: #f43f5e;
    --colors-rose-600: #e11d48;
    --colors-rose-700: #be123c;
    --colors-rose-800: #9f1239;
    --colors-rose-900: #881337;
    --colors-rose-950: #4c0519;
    --colors-pink-50: #fdf2f8;
    --colors-pink-100: #fce7f3;
    --colors-pink-200: #fbcfe8;
    --colors-pink-300: #f9a8d4;
    --colors-pink-400: #f472b6;
    --colors-pink-500: #ec4899;
    --colors-pink-600: #db2777;
    --colors-pink-700: #be185d;
    --colors-pink-800: #9d174d;
    --colors-pink-900: #831843;
    --colors-pink-950: #500724;
    --colors-fuchsia-50: #fdf4ff;
    --colors-fuchsia-100: #fae8ff;
    --colors-fuchsia-200: #f5d0fe;
    --colors-fuchsia-300: #f0abfc;
    --colors-fuchsia-400: #e879f9;
    --colors-fuchsia-500: #d946ef;
    --colors-fuchsia-600: #c026d3;
    --colors-fuchsia-700: #a21caf;
    --colors-fuchsia-800: #86198f;
    --colors-fuchsia-900: #701a75;
    --colors-fuchsia-950: #4a044e;
    --colors-violet-50: #f5f3ff;
    --colors-violet-100: #ede9fe;
    --colors-violet-200: #ddd6fe;
    --colors-violet-300: #c4b5fd;
    --colors-violet-400: #a78bfa;
    --colors-violet-500: #8b5cf6;
    --colors-violet-600: #7c3aed;
    --colors-violet-700: #6d28d9;
    --colors-violet-800: #5b21b6;
    --colors-violet-900: #4c1d95;
    --colors-violet-950: #2e1065;
    --colors-indigo-50: #eef2ff;
    --colors-indigo-100: #e0e7ff;
    --colors-indigo-200: #c7d2fe;
    --colors-indigo-300: #a5b4fc;
    --colors-indigo-400: #818cf8;
    --colors-indigo-500: #6366f1;
    --colors-indigo-600: #4f46e5;
    --colors-indigo-700: #4338ca;
    --colors-indigo-800: #3730a3;
    --colors-indigo-900: #312e81;
    --colors-indigo-950: #1e1b4b;
    --colors-blue-50: #eff6ff;
    --colors-blue-100: #dbeafe;
    --colors-blue-200: #bfdbfe;
    --colors-blue-300: #93c5fd;
    --colors-blue-400: #60a5fa;
    --colors-blue-500: #3b82f6;
    --colors-blue-600: #2563eb;
    --colors-blue-700: #1d4ed8;
    --colors-blue-800: #1e40af;
    --colors-blue-900: #1e3a8a;
    --colors-blue-950: #172554;
    --colors-sky-50: #f0f9ff;
    --colors-sky-100: #e0f2fe;
    --colors-sky-200: #bae6fd;
    --colors-sky-300: #7dd3fc;
    --colors-sky-400: #38bdf8;
    --colors-sky-500: #0ea5e9;
    --colors-sky-600: #0284c7;
    --colors-sky-700: #0369a1;
    --colors-sky-800: #075985;
    --colors-sky-900: #0c4a6e;
    --colors-sky-950: #082f49;
    --colors-cyan-50: #ecfeff;
    --colors-cyan-100: #cffafe;
    --colors-cyan-200: #a5f3fc;
    --colors-cyan-300: #67e8f9;
    --colors-cyan-400: #22d3ee;
    --colors-cyan-500: #06b6d4;
    --colors-cyan-600: #0891b2;
    --colors-cyan-700: #0e7490;
    --colors-cyan-800: #155e75;
    --colors-cyan-900: #164e63;
    --colors-cyan-950: #083344;
    --colors-emerald-50: #ecfdf5;
    --colors-emerald-100: #d1fae5;
    --colors-emerald-200: #a7f3d0;
    --colors-emerald-300: #6ee7b7;
    --colors-emerald-400: #34d399;
    --colors-emerald-500: #10b981;
    --colors-emerald-600: #059669;
    --colors-emerald-700: #047857;
    --colors-emerald-800: #065f46;
    --colors-emerald-900: #064e3b;
    --colors-emerald-950: #022c22;
    --colors-green-50: #f0fdf4;
    --colors-green-100: #dcfce7;
    --colors-green-200: #bbf7d0;
    --colors-green-300: #86efac;
    --colors-green-400: #4ade80;
    --colors-green-500: #22c55e;
    --colors-green-600: #16a34a;
    --colors-green-700: #15803d;
    --colors-green-800: #166534;
    --colors-green-900: #14532d;
    --colors-green-950: #052e16;
    --colors-lime-50: #f7fee7;
    --colors-lime-100: #ecfccb;
    --colors-lime-200: #d9f99d;
    --colors-lime-300: #bef264;
    --colors-lime-400: #a3e635;
    --colors-lime-500: #84cc16;
    --colors-lime-600: #65a30d;
    --colors-lime-700: #4d7c0f;
    --colors-lime-800: #3f6212;
    --colors-lime-900: #365314;
    --colors-lime-950: #1a2e05;
    --colors-yellow-50: #fefce8;
    --colors-yellow-100: #fef9c3;
    --colors-yellow-200: #fef08a;
    --colors-yellow-300: #fde047;
    --colors-yellow-400: #facc15;
    --colors-yellow-500: #eab308;
    --colors-yellow-600: #ca8a04;
    --colors-yellow-700: #a16207;
    --colors-yellow-800: #854d0e;
    --colors-yellow-900: #713f12;
    --colors-yellow-950: #422006;
    --colors-amber-50: #fffbeb;
    --colors-amber-100: #fef3c7;
    --colors-amber-200: #fde68a;
    --colors-amber-300: #fcd34d;
    --colors-amber-400: #fbbf24;
    --colors-amber-500: #f59e0b;
    --colors-amber-600: #d97706;
    --colors-amber-700: #b45309;
    --colors-amber-800: #92400e;
    --colors-amber-900: #78350f;
    --colors-amber-950: #451a03;
    --colors-red-50: #fef2f2;
    --colors-red-100: #fee2e2;
    --colors-red-200: #fecaca;
    --colors-red-300: #fca5a5;
    --colors-red-400: #f87171;
    --colors-red-500: #ef4444;
    --colors-red-600: #dc2626;
    --colors-red-700: #b91c1c;
    --colors-red-800: #991b1b;
    --colors-red-900: #7f1d1d;
    --colors-red-950: #450a0a;
    --colors-neutral-50: #fafafa;
    --colors-neutral-100: #f5f5f5;
    --colors-neutral-200: #e5e5e5;
    --colors-neutral-300: #d4d4d4;
    --colors-neutral-400: #a3a3a3;
    --colors-neutral-500: #737373;
    --colors-neutral-600: #525252;
    --colors-neutral-700: #404040;
    --colors-neutral-800: #262626;
    --colors-neutral-900: #171717;
    --colors-neutral-950: #0a0a0a;
    --colors-stone-50: #fafaf9;
    --colors-stone-100: #f5f5f4;
    --colors-stone-200: #e7e5e4;
    --colors-stone-300: #d6d3d1;
    --colors-stone-400: #a8a29e;
    --colors-stone-500: #78716c;
    --colors-stone-600: #57534e;
    --colors-stone-700: #44403c;
    --colors-stone-800: #292524;
    --colors-stone-900: #1c1917;
    --colors-stone-950: #0c0a09;
    --colors-zinc-50: #fafafa;
    --colors-zinc-100: #f4f4f5;
    --colors-zinc-200: #e4e4e7;
    --colors-zinc-300: #d4d4d8;
    --colors-zinc-400: #a1a1aa;
    --colors-zinc-500: #71717a;
    --colors-zinc-600: #52525b;
    --colors-zinc-700: #3f3f46;
    --colors-zinc-800: #27272a;
    --colors-zinc-900: #18181b;
    --colors-zinc-950: #09090b;
    --colors-slate-50: #f8fafc;
    --colors-slate-100: #f1f5f9;
    --colors-slate-200: #e2e8f0;
    --colors-slate-300: #cbd5e1;
    --colors-slate-400: #94a3b8;
    --colors-slate-500: #64748b;
    --colors-slate-600: #475569;
    --colors-slate-700: #334155;
    --colors-slate-800: #1e293b;
    --colors-slate-900: #0f172a;
    --colors-slate-950: #020617;
    --colors-current: currentColor;
    --colors-dark: #121526;
    --colors-dark-alpha: #121526E5;
    --colors-light: #ffffff;
    --colors-light-alpha: #FFFFFFCF;
    --colors-black: #000;
    --colors-white: #fff;
    --colors-brand2: #04C77C;
    --colors-brand: #f2055c;
    --colors-brand-alpha: rgba(242, 5, 92, 1.0);
    --colors-offwhite: #e8e7e3;
    --colors-bluealt: #82b0c4;
    --colors-yell: #fdff7f;
    --colors-gray: #f9f9f9;
    --colors-darkgray: #1B1D28;
    --colors-teal: #00CED1;
    --colors-orange: #FF6347;
    --colors-purple: #9932CC;
    --breakpoints-sm: 640px;
    --breakpoints-md: 768px;
    --breakpoints-lg: 1024px;
    --breakpoints-xl: 1280px;
    --breakpoints-2xl: 1536px;
    --colors-bg-main: #121526;
    --colors-bg-brand: #f2055c;
    --colors-bg-card: #f9f9f9;
    --colors-bg-cardreverse: #1B1D28;
    --colors-bg-lighttodark: #ffffff;
    --colors-bg-darktolight: #121526;
    --colors-bg-darktobrand: #121526;
    --colors-bg-lighttocard: #ffffff;
    --colors-bg-darktoblue: #121526;
    --colors-bg-graytodark: #f9f9f9;
    --colors-bg-thumbcolor: #ffffff;
    --colors-text-main: #121526;
    --colors-text-card: #1B1D28;
    --colors-text-muted: var(--colors-gray-800);
    --colors-color-palette-50: var(--colors-color-palette-50);
    --colors-color-palette-100: var(--colors-color-palette-100);
    --colors-color-palette-200: var(--colors-color-palette-200);
    --colors-color-palette-300: var(--colors-color-palette-300);
    --colors-color-palette-400: var(--colors-color-palette-400);
    --colors-color-palette-500: var(--colors-color-palette-500);
    --colors-color-palette-600: var(--colors-color-palette-600);
    --colors-color-palette-700: var(--colors-color-palette-700);
    --colors-color-palette-800: var(--colors-color-palette-800);
    --colors-color-palette-900: var(--colors-color-palette-900);
    --colors-color-palette-950: var(--colors-color-palette-950);
    --colors-color-palette-main: var(--colors-color-palette-main);
    --colors-color-palette-brand: var(--colors-color-palette-brand);
    --colors-color-palette-card: var(--colors-color-palette-card);
    --colors-color-palette-cardreverse: var(--colors-color-palette-cardreverse);
    --colors-color-palette-lighttodark: var(--colors-color-palette-lighttodark);
    --colors-color-palette-darktolight: var(--colors-color-palette-darktolight);
    --colors-color-palette-darktobrand: var(--colors-color-palette-darktobrand);
    --colors-color-palette-lighttocard: var(--colors-color-palette-lighttocard);
    --colors-color-palette-darktoblue: var(--colors-color-palette-darktoblue);
    --colors-color-palette-graytodark: var(--colors-color-palette-graytodark);
    --colors-color-palette-thumbcolor: var(--colors-color-palette-thumbcolor);
    --colors-color-palette-muted: var(--colors-color-palette-muted)
}

  :where(.dark, [data-mantine-color-scheme="dark"]) {
    --colors-bg-main: #ffffff;
    --colors-bg-brand: #f2055c;
    --colors-bg-card: #1B1D28;
    --colors-bg-cardreverse: #f9f9f9;
    --colors-bg-lighttodark: #121526;
    --colors-bg-darktolight: #ffffff;
    --colors-bg-darktobrand: #f2055c;
    --colors-bg-lighttocard: #121526;
    --colors-bg-darktoblue: #82b0c4;
    --colors-bg-graytodark: #121526;
    --colors-bg-thumbcolor: var(--colors-gray-100);
    --colors-text-main: #fff;
    --colors-text-card: #f9f9f9;
    --colors-text-muted: var(--colors-gray-50)
}

  @keyframes spin {
    to {
      transform: rotate(360deg)
        }
    }

  @keyframes ping {
    75%, 100% {
      transform: scale(2);
      opacity: 0
        }
    }

  @keyframes pulse {
    50% {
      opacity: .5
        }
    }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8,0,1,1)
        }

    50% {
      transform: none;
      animation-timing-function: cubic-bezier(0,0,0.2,1)
        }
    }

  @keyframes fadein {
    0% {
      opacity: 0
        }

    100% {
      opacity: 1
        }
    }

  @keyframes fadein2 {
    0% {
      opacity: 0
        }

    100% {
      opacity: 1
        }
    }

  @keyframes fadeout {
    0% {
      opacity: 1
        }

    100% {
      opacity: 0
        }
    }
  }@layer utilities {
  .w_500px {
    width: 500px
    }

  .mt_45vh {
    margin-top: 45vh
    }

  .disabled\:text_var\(--colors-bg-darktolight\):is(:disabled, [disabled], [data-disabled]) {
    color: var(--colors-bg-darktolight)
        }

  .disabled\:opacity_0\.4:is(:disabled, [disabled], [data-disabled]) {
    opacity: 0.4
        }

  .disabled\:cursor_not-allowed:is(:disabled, [disabled], [data-disabled]) {
    cursor: not-allowed
        }

  .size_h4 {
    size: h4
    }

  .pb_2px {
    padding-bottom: 2px
    }

  .w_45\% {
    width: 45%
    }

  .bg_var\(--colors-bg-lighttodarks\) {
    background: var(--colors-bg-lighttodarks)
    }

  .text_var\(--colors-bluealt\) {
    color: var(--colors-bluealt)
    }

  .w_48\% {
    width: 48%
    }

  .margin\:top_10 {
    top: var(--spacing-10)
    }

  .margin\:right_5 {
    right: var(--spacing-5)
    }

  .margin\:left_-25 {
    left: -25px
    }

  .margin\:bottom_0 {
    bottom: var(--spacing-0)
    }

  .text_gray {
    color: var(--colors-gray)
    }

  .items_flex-end {
    align-items: flex-end
    }

  .border_var\(--colors-bg-darktolight\) {
    border-color: var(--colors-bg-darktolight)
    }

  .h_500px {
    height: 500px
    }

  .p_lg {
    padding: lg
    }

  .pos_apart {
    position: apart
    }

  .transition_fade {
    transition: fade
    }

  .mt_80 {
    margin-top: var(--spacing-80)
    }

  .mr_-20 {
    margin-right: calc(var(--spacing-20) * -1)
    }

  .ml_5px {
    margin-left: 5px
    }

  .z_99 {
    z-index: 99
    }

  .p_0\.5px {
    padding: 0.5px
    }

  .mr_5px {
    margin-right: 5px
    }

  .text_var\(--colors-bg-main\) {
    color: var(--colors-bg-main)
    }

  .h_18em {
    height: 18em
    }

  .w_125,.w_125px {
    width: 125px
    }

  .h_125px {
    height: 125px
    }

  .max-w_100\% {
    max-width: 100%
    }

  .order_1 {
    order: 1
    }

  .size_48px {
    size: 48px
    }

  .px_8 {
    padding-inline: var(--spacing-8)
    }

  .w_80\% {
    width: 80%
    }

  .order_4 {
    order: 4
    }

  .size_32px {
    size: 32px
    }

  .mb_6 {
    margin-bottom: var(--spacing-6)
    }

  .p_md {
    padding: md
    }

  .bg_var\(--colors-bg-lighttodark\) {
    background: var(--colors-bg-lighttodark)
    }

  .bg_var\(--colors-brand-light\) {
    background: var(--colors-brand-light)
    }

  .my_50px {
    margin-block: 50px
    }

  .mb_xs {
    margin-bottom: xs
    }

  .mb_md {
    margin-bottom: md
    }

  .py_xs {
    padding-block: xs
    }

  .mb_lg {
    margin-bottom: lg
    }

  .my_lg {
    margin-block: lg
    }

  .mb_75px {
    margin-bottom: 75px
    }

  .scale_80\% {
    scale: 80%
    }

  .mb_10px {
    margin-bottom: 10px
    }

  .gap_-50px {
    gap: -50px
    }

  .px_5px {
    padding-inline: 5px
    }

  .pl_10px {
    padding-left: 10px
    }

  .my_-40px {
    margin-block: -40px
    }

  .mx_-10px {
    margin-inline: -10px
    }

  .w_11em {
    width: 11em
    }

  .opacity_0 {
    opacity: 0
    }

  .animation_fadein_0\.6s_ease-in-out_0\.6s_forwards {
    animation: fadein 0.6s ease-in-out 0.6s forwards
    }

  .top_16\.7em {
    top: 16.7em
    }

  .fs_24px {
    font-size: 24px
    }

  .mb_25px {
    margin-bottom: 25px
    }

  .pb_25px {
    padding-bottom: 25px
    }

  .grow_true {
    grow: true
    }

  .pl_20px {
    padding-left: 20px
    }

  .z_5 {
    z-index: 5
    }

  .pl_5\% {
    padding-left: 5%
    }

  .pr_5\% {
    padding-right: 5%
    }

  .pt_115px {
    padding-top: 115px
    }

  .gap_lg {
    gap: lg
    }

  .text_\#000 {
    color: #000
    }

  .z_9 {
    z-index: 9
    }

  .ml_250px {
    margin-left: 250px
    }

  .p_xs {
    padding: xs
    }

  .mt_200px {
    margin-top: 200px
    }

  .margin\:top_20 {
    top: var(--spacing-20)
    }

  .margin\:right_30 {
    right: 30px
    }

  .margin\:left_20 {
    left: var(--spacing-20)
    }

  .margin\:bottom_35 {
    bottom: 35px
    }

  .opacity_0\.3 {
    opacity: 0.3
    }

  .h_60 {
    height: var(--sizes-60)
    }

  .stroke_var\(--colors-brand2\) {
    stroke: var(--colors-brand2)
    }

  .stroke_var\(--colors-bluealt\) {
    stroke: var(--colors-bluealt)
    }

  .text_var\(--colors-bg-card\) {
    color: var(--colors-bg-card)
    }

  .scale_200\% {
    scale: 200%
    }

  .p_30 {
    padding: 30px
    }

  .mt_30 {
    margin-top: 30px
    }

  .text_red {
    color: red
    }

  .text_green {
    color: green
    }

  .justify_space-between {
    justify: space-between
    }

  .mt_lg {
    margin-top: lg
    }

  .mt_xl {
    margin-top: xl
    }

  .size_420 {
    size: 420px
    }

  .my_40 {
    margin-block: var(--spacing-40)
    }

  .h_12px {
    height: 12px
    }

  .w_12px {
    width: 12px
    }

  .pr_3 {
    padding-right: var(--spacing-3)
    }

  .size_h3 {
    size: h3
    }

  .h_10px {
    height: 10px
    }

  .w_10px {
    width: 10px
    }

  .mr_10px {
    margin-right: 10px
    }

  .py_xl {
    padding-block: xl
    }

  .px_md {
    padding-inline: md
    }

  .gap_0\.5px {
    gap: 0.5px
    }

  .text_var\(--colors-bg-lighttodark\) {
    color: var(--colors-bg-lighttodark)
    }

  .size_5 {
    size: 5px
    }

  .mr_10 {
    margin-right: var(--spacing-10)
    }

  .offset_10 {
    offset: 10px
    }

  .pos_fixed {
    position: fixed
    }

  .bottom_15px {
    bottom: 15px
    }

  .left_50\% {
    left: 50%
    }

  .transform_translate\(-50\%\,_-50\%\) {
    transform: translate(-50%, -50%)
    }

  .m_0_auto {
    margin: 0 auto
    }

  .min-w_275px {
    min-width: 275px
    }

  .bg_var\(--colors-bg-darktobrand\) {
    background: var(--colors-bg-darktobrand)
    }

  .fs_sm {
    font-size: var(--font-sizes-sm)
    }

  .h_35px {
    height: 35px
    }

  .z_2 {
    z-index: 2
    }

  .px_2px {
    padding-inline: 2px
    }

  .px_1px {
    padding-inline: 1px
    }

  .pr_2px {
    padding-right: 2px
    }

  .my_auto {
    margin-block: auto
    }

  .\--thickness_1px {
    --thickness: 1px
    }

  .border-block-end-width_var\(--thickness\) {
    border-block-end-width: var(--thickness)
    }

  .size_75 {
    size: 75px
    }

  .h_75px {
    height: 75px
    }

  .w_75px {
    width: 75px
    }

  .text_var\(--colors-bg-light\) {
    color: var(--colors-bg-light)
    }

  .w_165px {
    width: 165px
    }

  .w_18 {
    width: 18px
    }

  .justify_left {
    justify: left
    }

  .w_20 {
    width: var(--sizes-20)
    }

  .h_20 {
    height: var(--sizes-20)
    }

  .stroke_1\.5 {
    stroke: 1.5px
    }

  .gap_0\.2 {
    gap: 0.2px
    }

  .max-h_80vh {
    max-height: 80vh
    }

  .mb_15 {
    margin-bottom: 15px
    }

  .text_var\(--colors-brand2\) {
    color: var(--colors-brand2)
    }

  .text_var\(--colors-text-card\) {
    color: var(--colors-text-card)
    }

  .size_md {
    size: md
    }

  .ml_10px {
    margin-left: 10px
    }

  .p_15px {
    padding: 15px
    }

  .pt_0px {
    padding-top: 0px
    }

  .mb_50\% {
    margin-bottom: 50%
    }

  .mb_45\% {
    margin-bottom: 45%
    }

  .placement_top-start {
    placement: top-start
    }

  .offset_5 {
    offset: 5
    }

  .mb_-5 {
    margin-bottom: calc(var(--spacing-5) * -1)
    }

  .size_100 {
    size: 100px
    }

  .size_125 {
    size: 125px
    }

  .h_16\.5em {
    height: 16.5em
    }

  .mr_-25px {
    margin-right: -25px
    }

  .mr_0px {
    margin-right: 0px
    }

  .ml_-15px {
    margin-left: -15px
    }

  .ml_0px {
    margin-left: 0px
    }

  .top_14em {
    top: 14em
    }

  .fs_18px {
    font-size: 18px
    }

  .white-space_nowrap {
    white-space: nowrap
    }

  .text_ellipsis,.truncate_end {
    text-overflow: ellipsis
    }

  .truncate_end {
    overflow: hidden;
    white-space: nowrap
    }

  .w_180px {
    width: 180px
    }

  .z_1 {
    z-index: 1
    }

  .top_9em {
    top: 9em
    }

  .right_10px {
    right: 10px
    }

  .d_inline-flex {
    display: inline-flex
    }

  .inset-t_5 {
    inset-block-start: var(--spacing-5)
    }

  .inset-b_auto {
    inset-block-end: auto
    }

  .start_5 {
    inset-inline-start: var(--spacing-5)
    }

  .end_auto {
    inset-inline-end: auto
    }

  .translate_-50\%_-50\% {
    translate: -50% -50%
    }

  .gap_5 {
    gap: var(--spacing-5)
    }

  .pl_5px {
    padding-left: 5px
    }

  .pt_2 {
    padding-top: var(--spacing-2)
    }

  .pt_5px {
    padding-top: 5px
    }

  .flex_0_0_auto {
    flex: 0 0 auto
    }

  .h_155px {
    height: 155px
    }

  .rounded_9999px {
    border-radius: 9999px
    }

  .duration_0\.2s {
    --transition-duration: 0.2s;
    transition-duration: 0.2s
    }

  .ease_ease-in-out {
    --transition-easing: ease-in-out;
    transition-timing-function: ease-in-out
    }

  .h_125 {
    height: 125px
    }

  .mb_xl {
    margin-bottom: xl
    }

  .h_19em {
    height: 19em
    }

  .overflow_hidden {
    overflow: hidden
    }

  .bg_none {
    background: none
    }

  .px_15px {
    padding-inline: 15px
    }

  .h_150 {
    height: 150px
    }

  .h_10 {
    height: var(--sizes-10)
    }

  .mt_6 {
    margin-top: var(--spacing-6)
    }

  .mb_sm {
    margin-bottom: sm
    }

  .w_95\% {
    width: 95%
    }

  .max-w_375px {
    max-width: 375px
    }

  .h_11em {
    height: 11em
    }

  .shadow_xl {
    box-shadow: var(--shadows-xl)
    }

  .rounded_lg {
    border-radius: var(--radii-lg)
    }

  .w_175px {
    width: 175px
    }

  .mt_35px {
    margin-top: 35px
    }

  .size_14px {
    size: 14px
    }

  .gap_xl {
    gap: xl
    }

  .gap_30vw {
    gap: 30vw
    }

  .gap_1 {
    gap: var(--spacing-1)
    }

  .overflow_scroll {
    overflow: scroll
    }

  .h_100px {
    height: 100px
    }

  .w_150px {
    width: 150px
    }

  .gap_8 {
    gap: var(--spacing-8)
    }

  .mt_-100px {
    margin-top: -100px
    }

  .bg_var\(--colors-yell\) {
    background: var(--colors-yell)
    }

  .p_50px {
    padding: 50px
    }

  .mt_25px {
    margin-top: 25px
    }

  .text_var\(--colors-dark\) {
    color: var(--colors-dark)
    }

  .gap_25\% {
    gap: 25%
    }

  .w_50 {
    width: 50px
    }

  .text_var\(--colors-brand\) {
    color: var(--colors-brand)
    }

  .shadow_md {
    box-shadow: var(--shadows-md)
    }

  .p_xl {
    padding: xl
    }

  .mt_md {
    margin-top: md
    }

  .size_2rem {
    size: 2rem
    }

  .order_3 {
    order: 3
    }

  .order_2 {
    order: 2
    }

  .mt_sm {
    margin-top: sm
    }

  .mt_50 {
    margin-top: 50px
    }

  .w_350px {
    width: 350px
    }

  .mt_75px {
    margin-top: 75px
    }

  .pt_75px {
    padding-top: 75px
    }

  .w_75\% {
    width: 75%
    }

  .size_1\.5rem {
    size: 1.5rem
    }

  .size_xl {
    size: xl
    }

  .w_300px {
    width: 300px
    }

  .mr_100px {
    margin-right: 100px
    }

  .mt_50px {
    margin-top: 50px
    }

  .justify_flex-start {
    justify-content: flex-start
    }

  .pt_200px {
    padding-top: 200px
    }

  .text_var\(--colors-bg-brand\) {
    color: var(--colors-bg-brand)
    }

  .size_3rem {
    size: 3rem
    }

  .pt_60px {
    padding-top: 60px
    }

  .pb_50px {
    padding-bottom: 50px
    }

  .w_100 {
    width: 100px
    }

  .h_50 {
    height: 50px
    }

  .size_1rem {
    size: 1rem
    }

  .mb_-25 {
    margin-bottom: -25
    }

  .direction_left {
    direction: left
    }

  .direction_right {
    direction: right
    }

  .bg_\#fdff7f {
    background: #fdff7f
    }

  .pb_75 {
    padding-bottom: 75
    }

  .shadow_sm {
    box-shadow: var(--shadows-sm)
    }

  .z_10 {
    z-index: 10
    }

  .h_400px {
    height: 400px
    }

  .text_var\(--colors-bg-darktobrand\) {
    color: var(--colors-bg-darktobrand)
    }

  .mt_10px {
    margin-top: 10px
    }

  .pb_10px {
    padding-bottom: 10px
    }

  .mb_100px {
    margin-bottom: 100px
    }

  .ml_-8px {
    margin-left: -8px
    }

  .mr_15px {
    margin-right: 15px
    }

  .h_16px {
    height: 16px
    }

  .w_16px {
    width: 16px
    }

  .text_light,.text_var\(--colors-light\) {
    color: var(--colors-light)
    }

  .mt_20px {
    margin-top: 20px
    }

  .mt_15px {
    margin-top: 15px
    }

  .size_20px {
    size: 20px
    }

  .clamp_1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
    }

  .d_none {
    display: none
    }

  .py_8 {
    padding-block: var(--spacing-8)
    }

  .pr_10 {
    padding-right: var(--spacing-10)
    }

  .pl_10 {
    padding-left: var(--spacing-10)
    }

  .top_6 {
    top: var(--spacing-6)
    }

  .w_full {
    width: var(--sizes-full)
    }

  .max-w_8xl {
    max-width: var(--sizes-8xl)
    }

  .mx_auto {
    margin-inline: auto
    }

  .pos_absolute {
    position: absolute
    }

  .top_12px {
    top: 12px
    }

  .right_12px {
    right: 12px
    }

  .size_xs {
    size: xs
    }

  .gap_xs {
    gap: xs
    }

  .justify_flex-end {
    justify: flex-end
    }

  .wrap_nowrap {
    wrap: nowrap
    }

  .pt_10px {
    padding-top: 10px
    }

  .size_lg {
    size: lg
    }

  .px_75px {
    padding-inline: 75px
    }

  .py_25px {
    padding-block: 25px
    }

  .w_100px {
    width: 100px
    }

  .h_16 {
    height: var(--sizes-16)
    }

  .py_5 {
    padding-block: var(--spacing-5)
    }

  .px_4 {
    padding-inline: var(--spacing-4)
    }

  .backdrop_auto {
    backdrop-filter: var(--backdrop-blur) var(--backdrop-brightness) var(--backdrop-contrast) var(--backdrop-grayscale) var(--backdrop-hue-rotate) var(--backdrop-invert) var(--backdrop-saturate) var(--backdrop-sepia);
    -webkit-backdrop-filter: var(--backdrop-blur) var(--backdrop-brightness) var(--backdrop-contrast) var(--backdrop-grayscale) var(--backdrop-hue-rotate) var(--backdrop-invert) var(--backdrop-saturate) var(--backdrop-sepia)
    }

  .backdrop-blur_sm {
    --backdrop-blur: blur(var(--blurs-sm))
    }

  .bg_lightAlpha {
    background: var(--colors-light-alpha)
    }

  .dark .dark\:bg_darkAlpha, [data-mantine-color-scheme="dark"] .dark\:bg_darkAlpha {
    background: var(--colors-dark-alpha)
        }

  .gap_4 {
    gap: var(--spacing-4)
    }

  .shrink_0 {
    flex-shrink: 0
    }

  .h_18 {
    height: 18px
    }

  .pl_5 {
    padding-left: var(--spacing-5)
    }

  .pr_5 {
    padding-right: var(--spacing-5)
    }

  .max-w_1000px {
    max-width: 1000px
    }

  .px_2 {
    padding-inline: var(--spacing-2)
    }

  .font_semibold {
    font-weight: var(--font-weights-semibold)
    }

  .tracking_tight {
    letter-spacing: var(--letter-spacings-tight)
    }

  .rounded_md {
    border-radius: var(--radii-md)
    }

  .text-decor_none {
    text-decoration: none
    }

  .gap_2 {
    gap: var(--spacing-2)
    }

  .left_150px {
    left: 150px
    }

  .px_1 {
    padding-inline: var(--spacing-1)
    }

  .py_1 {
    padding-block: var(--spacing-1)
    }

  .w_1em {
    width: 1em
    }

  .h_auto {
    height: auto
    }

  .transition_slide-right {
    transition: slide-right
    }

  .h_100vh {
    height: 100vh
    }

  .size_sm {
    size: sm
    }

  .text_dimmed {
    color: dimmed
    }

  .w_250px {
    width: 250px
    }

  .h_150\% {
    height: 150%
    }

  .bg_light {
    background: var(--colors-light)
    }

  .dark .dark\:bg_dark, [data-mantine-color-scheme="dark"] .dark\:bg_dark {
    background: var(--colors-dark)
        }

  .pb_100px {
    padding-bottom: 100px
    }

  .d_None {
    display: None
    }

  .py_5px {
    padding-block: 5px
    }

  .justify_space-between {
    justify-content: space-between
    }

  .mb_2 {
    margin-bottom: var(--spacing-2)
    }

  .w_130px {
    width: 130px
    }

  .rounded_50px {
    border-radius: 50px
    }

  .pr_4 {
    padding-right: var(--spacing-4)
    }

  .pb_2 {
    padding-bottom: var(--spacing-2)
    }

  .w_75 {
    width: 75px
    }

  .h_75 {
    height: 75px
    }

  .size_16px {
    size: 16px
    }

  .size_21px {
    size: 21px
    }

  .text_var\(--colors-bg-darktolight\) {
    color: var(--colors-bg-darktolight)
    }

  .h_25px {
    height: 25px
    }

  .size_12px {
    size: 12px
    }

  .mt_-10px {
    margin-top: -10px
    }

  .gap_25px {
    gap: 25px
    }

  .gap_5px {
    gap: 5px
    }

  .p_auto {
    padding: auto
    }

  .py_50px {
    padding-block: 50px
    }

  .w_200px {
    width: 200px
    }

  .px_25px {
    padding-inline: 25px
    }

  .size_18px {
    size: 18px
    }

  .mt_150px {
    margin-top: 150px
    }

  .gap_md {
    gap: md
    }

  .mt_-3px {
    margin-top: -3px
    }

  .py_3 {
    padding-block: var(--spacing-3)
    }

  .w_155px {
    width: 155px
    }

  .p_10px {
    padding: 10px
    }

  .h_300px {
    height: 300px
    }

  .pos_relative {
    position: relative
    }

  .gap_0\.5 {
    gap: var(--spacing-0\.5)
    }

  .mt_5px {
    margin-top: 5px
    }

  .size_28px {
    size: 28px
    }

  .size_4rem {
    size: 4rem
    }

  .align_left {
    align: left
    }

  .pb_15px {
    padding-bottom: 15px
    }

  .align_center {
    align: center
    }

  .size_24px {
    size: 24px
    }

  .px_50px {
    padding-inline: 50px
    }

  .justify_center {
    justify: center
    }

  .align_flex-start {
    align: flex-start
    }

  .bg_var\(--colors-bg-card\) {
    background: var(--colors-bg-card)
    }

  .shadow_lg {
    box-shadow: var(--shadows-lg)
    }

  .h_615px {
    height: 615px
    }

  .w_90\% {
    width: 90%
    }

  .mb_15px {
    margin-bottom: 15px
    }

  .px_100px {
    padding-inline: 100px
    }

  .mt_100px {
    margin-top: 100px
    }

  .w_100\% {
    width: 100%
    }

  .h_100\% {
    height: 100%
    }

  .items_flex-start {
    align-items: flex-start
    }

  .flex_row {
    flex-direction: row
    }

  .flex-wrap_nowrap {
    flex-wrap: nowrap
    }

  .pt_25px {
    padding-top: 25px
    }

  .flex_column {
    flex-direction: column
    }

  .p_25px {
    padding: 25px
    }

  .d_grid {
    display: grid
    }

  .gap_10px {
    gap: 10px
    }

  .d_flex {
    display: flex
    }

  .items_center {
    align-items: center
    }

  .justify_center {
    justify-content: center
    }

  .pt_50px {
    padding-top: 50px
    }

  .hover\:text_var\(--colors-brand2\):is(:hover, [data-hover]) {
    color: var(--colors-brand2)
        }

  .hover\:text_var\(--colors-bg-darktolight\):is(:hover, [data-hover]) {
    color: var(--colors-bg-darktolight)
        }

  .hover\:text_brand2:is(:hover, [data-hover]) {
    color: var(--colors-brand2)
        }

  .hover\:transition_all_0\.1s_ease-in-out:is(:hover, [data-hover]) {
    transition: all 0.1s ease-in-out
        }

  .hover\:scale_1\.1:is(:hover, [data-hover]) {
    scale: 1.1
        }

  .hover\:size_150px:is(:hover, [data-hover]) {
    size: 150px
        }

  .hover\:duration_0\.2s:is(:hover, [data-hover]) {
    --transition-duration: 0.2s;
    transition-duration: 0.2s
        }

  .hover\:ease_ease-in-out:is(:hover, [data-hover]) {
    --transition-easing: ease-in-out;
    transition-timing-function: ease-in-out
        }

  .hover\:text_brand:is(:hover, [data-hover]) {
    color: var(--colors-brand)
        }

  @layer compositions {
    .textStyle_xl {
      font-size: 1.25rem;
      line-height: 1.75rem
        }
    }

  @media screen and (min-width: 640px) {
    .sm\:pt_100px {
      padding-top: 100px
    }

    .sm\:flex_column {
      flex-direction: column
    }

    .sm\:items_center {
      align-items: center
    }

    .sm\:mt_0px {
      margin-top: 0px
    }

    .sm\:d_block {
      display: block
    }
        }

  @media screen and (min-width: 640px) {
    .sm\:pl_5 {
      padding-left: var(--spacing-5)
    }
        }

  @media screen and (min-width: 640px) {
    .sm\:pr_5 {
      padding-right: var(--spacing-5)
    }
        }

  @media screen and (min-width: 640px) {
    .sm\:d_None {
      display: None
    }
        }

  @media screen and (min-width: 768px) {
    .md\:px_6 {
      padding-inline: var(--spacing-6)
    }
        }

  @media screen and (min-width: 768px) {
    .md\:pl_20 {
      padding-left: var(--spacing-20)
    }
        }

  @media screen and (min-width: 768px) {
    .md\:pr_10 {
      padding-right: var(--spacing-10)
    }
        }

  @media screen and (min-width: 768px) {
    .md\:w_250px {
      width: 250px
    }

    .md\:pt_110px {
      padding-top: 110px
    }

    .md\:shadow_none {
      box-shadow: none
    }
        }

  @media screen and (min-width: 768px) {
    .md\:d_Block {
      display: Block
    }
        }

  @media screen and (min-width: 1024px) {
    .lg\:px_8 {
      padding-inline: var(--spacing-8)
    }
        }
}

@font-face {
font-family: 'punch';
src: url(/_next/static/media/d18324b0fe9ce877-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'punch Fallback';src: local("Arial");ascent-override: 97.08%;descent-override: 28.18%;line-gap-override: 0.00%;size-adjust: 106.82%
}.__className_84bbd8 {font-family: 'punch', 'punch Fallback'
}

@font-face {
font-family: 'circle';
src: url(/_next/static/media/2092eb92143c7e67-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'circle Fallback';src: local("Arial");ascent-override: 98.02%;descent-override: 26.35%;line-gap-override: 0.00%;size-adjust: 101.72%
}.__className_b85f22 {font-family: 'circle', 'circle Fallback'
}

